import React from 'react'
import styled from '@emotion/styled'
require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 1.5rem 0 0.5rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.75em;
    border-bottom: 0.5px solid rgba(0,0,0,0.5);
    padding-bottom: 0.3rem;
  }
  h2 {
    font-size: 1.5em;
    border-bottom: 0.25px solid rgba(0,0,0,0.3);
    border-left: 10px solid rgba(0,0,0,0.3);
    padding-left: 0.3rem;
    padding-bottom: 0.2rem;
    
  }
  h3 {
    font-size: 1.25em;
    margin: 1.5rem 0 0.7rem 0;
    position: relative;
    padding: 0.8rem 0.75rem;
    border: 3px solid #d8d8d8;
    border-radius: 10px;
    background: #f9f9f9;
    display: inline-block;
  }

  h3:before {
    position: absolute;
    bottom: -14px;
    left: 1em;
    width: 0;
    height: 0;
    content: '';
    border-width: 14px 12px 0 12px;
    border-style: solid;
    border-color: #d8d8d8 transparent transparent transparent;
  }
  
  h3:after {
    position: absolute;
    bottom: -10px;
    left: 1em;
    width: 0;
    height: 0;
    content: '';
    border-width: 14px 12px 0 12px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
  }
  


  p {
    line-height: 1.6;
    margin: 0 0 1em 0;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 3px;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 3px;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody
